import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/nft-wallet/Banner';
import Whatis from '../components/nft-wallet/Whatis';
import FaqSection from '../components/nft-wallet/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import TechStack from '../components/nft-wallet/TechStack';
import TypeOf from '../components/nft-wallet/TypeOf';
import OurWide from '../components/nft-wallet/OurWide';
import DevProcess from '../components/nft-wallet/DevProcess';
import ExclusiveFeature from '../components/nft-wallet/ExclusiveFeature';
import WhyChoose from '../components/nft-wallet/WhyChoose';


const NftWallet = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-wallet-development-company/" />
        <title>NFT Wallet Development Company | White Label NFT Wallet</title>
        <meta name="description" content="Coinsclone, an ideal NFT Wallet Development company offers versatile software solutions to create superfine NFT wallets that supports various NFT Standards." />
        <meta name="keywords" content="NFT Wallet Development Company, NFT Wallet Development, NFT Wallet Development Services, Customized NFT Wallet, NFT Wallet Creation Service Provider, NFT Crypto Wallet Development, Non Fungible Token Wallet Development, NFT Wallet App Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Wallet Development Company | White Label NFT Wallet" />
        <meta property="og:description" content="Coinsclone, an ideal NFT Wallet Development company offers versatile software solutions to create superfine NFT wallets that supports various NFT Standards." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-wallet-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-wallet/nft-wallet-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone, an ideal NFT Wallet Development company offers versatile software solutions to create superfine NFT wallets that supports various NFT Standards." />
        <meta name="twitter:title" content="NFT Wallet Development Company | White Label NFT Wallet" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-wallet/nft-wallet-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Wallet Development Company</span>
        </div>
      </div>
      <Whatis />
      <TypeOf />
      <OurWide />
      <DevProcess />
      <ExclusiveFeature />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default NftWallet
