import React from "react"

class ExclusiveFeature extends React.Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (

      <section className="usr-admin-tab benifor nftwallet pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Exclusive Features</span> Of NFT Wallet Development
            </h2>
            <p className="text-center">
            Elevate your NFT experience with our cutting-edge NFT wallet features. Experience peace of mind with our below-listed unique and security features.
            </p>
            <div className="row">
            <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Basic Features
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Advanced Features
                </li>
              </ul>
              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                  <div className="d-lg-flex flex-wrap add-one px-0">
                    <div className="square" >
                      <h3>Multiple Device Support</h3>
                      <p className="pharagraph">
                      This feature will allow users to access their NFT wallet from various devices such as smartphones, tablets, desktops, etc. We ensure the flexibility and accessibility to your users.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Cross-Chain Compatibility</h3>
                      <p className="pharagraph">
                      We exclusively offer cross-chain compatibility to your users to store and manage the NFTs from multiple blockchain networks within the same wallet. We prioritize your user's flexibility and convenience.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Fiat Conversion with Credit</h3>
                      <p className="pharagraph">
                      We facilitate an effortless conversion between cryptos and fiat. We also included the ability to purchase cryptocurrencies using credit cards or other fiat payment methods as well. Thus we enhance the usability. 
                      </p>
                    </div>
                    <div className="square" >
                      <h3>User Friendly</h3>
                      <p className="pharagraph">
                      Our NFT wallet development focuses on creating user-friendly interfaces that captivate your users. NFT wallets provide simple account setups and effortless access to digital collectibles. This user-centric approach enhances user engagement and satisfaction on a broader scale.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Multi-Platform Support</h3>
                      <p className="pharagraph">
                      As a popular NFT development company, we provide support for multiple operating systems and platforms. This includes iOS, Android, Windows, and macOS, catering to a diverse range of users and devices.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Crypto Exchange API</h3>
                      <p className="pharagraph">
                      We integrate with cryptocurrency exchanges via APIs to allow users to trade cryptocurrencies directly within the wallet interface. Through this, we provide a convenient and efficient trading experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="d-lg-flex flex-wrap add-one px-0">
                    <div className="square" >
                      <h3>Robust Encryption</h3>
                      <p className="pharagraph">
                      To secure users' sensitive data and transactions, we implement strong encryption protocols in the NFT wallet development. Thus we protect against any unauthorized access and data breaches.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Multi-factor authentication (MFA)</h3>
                      <p className="pharagraph">
                      We have implemented MFA for your users to authenticate their identity through passwords, biometrics, one-time passwords, etc…This will add an extra layer of security to the login process.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Biometric Verification</h3>
                      <p className="pharagraph">
                      We offer biometric authentication options for your users such as fingerprint or facial recognition to verify users' identities securely and conveniently. With these security features, we can avoid fraudulent activity.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Anti-Phishing Measures</h3>
                      <p className="pharagraph">
                      To prevent highly dangerous phishing attacks, we have implemented some measures to detect and also give warnings. This incorporated feature will warn your users about any suspicious links, emails, etc... 
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Secure Private Key Management</h3>  
                      <p className="pharagraph">
                      In our NFT wallet development, we have employed robust mechanisms for private key management, including hardware wallets, encrypted hardware wallets, encrypted key storage, and secure backup solutions.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Cold Storage Support</h3>
                      <p className="pharagraph">
                      This feature enables offline storage of private keys for enhanced security. Our cold storage protects against any online hacking and malware attacks. This feature will allow users to transfer assets securely when needed and offer peace of mind knowing assets are stored offline.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ExclusiveFeature
