import React from "react"

class DevProcess extends React.Component {

  render() {
    return (

      <section className="dapps nftwallet pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">Our NFT Wallet <span className="bluecolor">Development Process</span></h2>
            <p className='text-center'>Simplify the complexities of the NFT wallet development process with our expert guidance, ensuring a smooth path to your digital dreams.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Requirements Gathering</h3>
                            <p className='pharagraph'>Our development process towards the NFT wallet starts by defining the particular requirements. We collect the necessary features and functionalities of your NFT wallet. The market trends, regulatory considerations, UI design, and integration with the NFT marketplace, all factors are considered at this stage.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Designing User Interface</h3>
                            <p className='pharagraph'>Here at Coinsclone, we have an excellent design team, who will design an intuitive and user-grabbing interface for your NFT wallet. Your NFT wallet UI/UX will allow your users to easily manage their NFT assets, transaction history, and interaction with the NFT marketplace.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Implementation of Wallet Functionality</h3>
                            <p className='pharagraph'>We develop the core functionality of your NFT wallet, including features such as wallet account creation, NFT token management (e.g., minting, transferring, viewing), transaction processing, and integration with blockchain networks. The entire prompt working development of your NFT wallet is held at this stage.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Integration Process</h3>
                            <p className='pharagraph'>We will be integrating robust security measures to protect users' NFT assets and sensitive data. This process includes encryption of private keys, multi-factor authentication (MFA), anti-phishing measures, secure private key management, etc...</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Testing and Debugging</h3>
                            <p className='pharagraph'>Our developers will indulge in a thorough testing of your NFT wallet to ensure that it functions as intended. We ensure your NFT wallet is free of bugs, vulnerabilities, and usability issues.  Our pioneering testing team will conduct both automated & manual testing to rectify the issue.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Deployment and Support</h3>
                            <p className='pharagraph'>With all the relevant regulatory requirements, we deploy your NFT wallet to the desired platforms. Further, we provide ongoing maintenance and support to address user feedback, update security features, and add new functionalities as well.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevProcess
