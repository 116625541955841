import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Choose</span> Coinsclone As Your NFT Wallet Development Company?</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-wallet/why-choose-nft-wallet.webp"
                alt="Why Choose NFT Wallet Development Company"
                placeholder='none'
                className='mt-3 mb-3'
                width={512}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone stands as a trusted partner when it comes to NFT wallet development. Our ultimate developer team will secure your digital treasures with our NFT wallets. As an ideal NFT wallet development company, we are always known for our in-depth knowledge of creating feature-rich NFT wallets and delivering revenue-generating products at affordable prices. If you've got a great idea, don't hesitate to do it, we're here to craft your unique NFT wallet.
              </p>
              <p className="pharagraph">At Coinsclone, we prioritize quality, reliability, and customer satisfaction in every project we undertake. In short, we curate the key to explore the world of unique assets. We have a streamlined NFT wallet development process with which you can store, trade, and explore the NFT ecosystem with our innovative NFT wallets. We always deliver a smooth product for your successful journey into the world of NFT.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose