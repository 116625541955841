import React from "react"

class TypeOf extends React.Component {

  render() {
    return (
      <section className="process-of types pt-100 mb-0">
            <div className="container">     
              <h2 className="heading-h2 text-center">Types of NFT Wallet Development <span className="bluecolor">We Offer</span>
              </h2>
              <p className="text-center">
              Secure your digital treasures with our diverse categories of NFT wallets.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square" >
                  <h3>Custodial NFT Wallet</h3>
                  <p className="pharagraph">
                  A custodial wallet will make your users rely on a third party to manage their private keys and facilitate transactions on their behalf. We are here to provide user-friendly interfaces, features, and functionalities that make it easier for users to manage their NFTs.
                  </p>
                </div>
                <div className="square">
                  <h3>Non-Custodial NFT Wallet</h3>
                  <p className="pharagraph">
                  Non-custodial wallet, allows users to have complete control over your user's crypto assets. We provide a higher level of security over user's private keys. We reduce the risk of hacking, and fraud, as there is no central point of failure for attackers to target. Users can enjoy enhanced security and privacy.
                  </p>
                </div>
                <div className="square" >
                  <h3>Hybrid Wallet</h3>
                  <p className="pharagraph">
                  A hybrid wallet combines the elements of both centralized and decentralized wallets. We aim to offer users the benefits of both, providing a balance between security, convenience, and user control. Seamlessly switch between centralized convenience and decentralized security.
                  </p>
                </div>
              </div>
            </div>
      </section>

    )
  }
}

export default TypeOf
