import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Coinsclone - <span className='bluecolor'>Best NFT Wallet </span>Development Company </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a premier NFT Wallet Development Company that helps startups craft NFT wallets that transcend the ordinary to the extraordinary platform. Your users can engage in the vibrant world of digital asset trading. With our NFT wallet at your fingertips, you can unlock the gateway to a realm of non-fungible tokens. This allows your users to tokenize everything into a digital asset that represents real-world objects like art, music, in-game items, videos, etc...As an NFT wallet development company, we assure your users can seamlessly transact and securely store assets within the NFT ecosystem.
              </p>
              <p className="pharagraph text-center mb-0">As a pre-eminent NFT wallet development company, we support and ensure your users can effortlessly trade. Our distinctive features and functionalities will attract users worldwide, making your wallet the go-to choice for any transaction. Join Coinsclone for the development of an NFT wallet, where innovation meets opportunity on a grand scale. With our world-class NFT wallet development services, attract a diverse cluster of users from every corner of the globe. From novices to experts, trade smarter and trade better with our advanced NFT wallet. You can get Premium perks entailing the immense possibilities that the NFT ecosystem has to offer.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis